// :cow: Cosmose CONFIDENTIAL :iso:
import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ImageSuggestionVariantListPayload } from '../models/image-suggestion-variant-list.payload';
import { ImageSuggestionVariantListResponse } from '../models/image-suggestion-variant-list.response';

export const getImageSuggestionVariantListRequested = createAction('[IMAGE SUGGESTIONS] Get Image Suggestion Variant List Requested', props<{
  payload: ImageSuggestionVariantListPayload
}>());
export const getImageSuggestionVariantListSucceeded = createAction('[IMAGE SUGGESTIONS] Get Image Suggestion Variant List Succeeded', props<{
  response: ImageSuggestionVariantListResponse,
}>());
export const getImageSuggestionVariantListFailed = createAction('[IMAGE SUGGESTIONS] Get Image Suggestion Variant List Failed', props<{
  httpErrorResponse: HttpErrorResponse
}>());
